@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .filter-glow {
    filter: url("#f1");
  }
}

body {
  font-family: "Inter";
  overflow-x: hidden;
}

.horizontal-fade-outs {
  mask-image: linear-gradient(
    to right,
    black calc(90% - 2px),
    transparent 100%
  );
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* CSS FOR GRADIENT TEXT */
.gradient_bg {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
}

.gradient_bg.black {
  background: linear-gradient(45deg, #101828 0%, #5d6b98 100%);
}

.gradient_bg.blue {
  background: linear-gradient(45deg, #065986 0%, #0086c9 100%);
}

.gradient_bg.red {
  background: linear-gradient(45deg, #a11043 0%, #e31b54 100%);
}

.gradient_bg.purple {
  background: linear-gradient(45deg, #821890 0%, #ba24d5 100%);
}

.gradient_bg.brown {
  background: linear-gradient(45deg, #854a0e 0%, #ca8504 100%);
}

.gradient_bg.green {
  background: linear-gradient(45deg, #326212 0%, #4ca30d 100%);
}

.gradient_bg.gray {
  background: linear-gradient(45deg, #293056 0%, #3e4784 100%);
}

/* CSS FOR PHONE NUMBER INPUT */
.PhoneInputCountry {
  margin-right: 1em !important;
}

.PhoneInputCountry .PhoneInputCountryIconUnicode {
  font-size: 20px;
  line-height: 24px;
  font-family: "Inter";
  color: #101828;
}

.PhoneInputCountry .PhoneInputCountrySelectArrow {
  width: 0.46em;
  height: 0.46em;
  border-bottom-width: 1.6px;
  border-right-width: 1.6px;
  margin-left: 0.6em;
}

.PhoneInputInput {
  border: none;
  border-radius: 0.5rem;
  color: #475467;
}

.PhoneInputInput::placeholder {
  color: #667085;
}

.PhoneInputInput:focus {
  --tw-ring-color: transparent;
}

/* CSS FOR MUI TABS */
.tabs {
  background-color: transparent;
}

.tabs .MuiTabs-scroller {
  background-color: transparent;
}

.tabs .MuiTabs-indicator {
  background: #7f56d9;
}

.tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  background-color: transparent;
}

.tabs .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
  text-transform: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.tabs
  .MuiTabs-scroller
  .MuiTabs-flexContainer
  .MuiButtonBase-root.Mui-selected {
  color: #7f56d9;
}

.transition-max-height {
  transition: max-height 0.25s ease-out;
}

.animated-text.fade-in {
  animation: fadeIn 0.5s ease-in;
}

.animated-text.fade-out {
  animation: fadeOut 0.5s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dropdown-hidden {
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: opacity 1s, transform 1s, visibility 1s;
}

.dropdown-visible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: opacity 1s, transform 1s;
}

.drag-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0.4, 0.439, 0.522, 0.75);
  color: #eaecf0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-direction: column;
}

.dotted-square {
  padding: 40px;
  border: 5px dashed #eaecf0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: calc(100% - 80px); /* Adjust the width based on padding */
  height: calc(100% - 80px); /* Adjust the height based on padding */
  text-align: center; /* Ensure the text is centered */
}

.inner-content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.inner-content span {
  display: inline-block;
}

.inner-content br {
  display: block; /* Ensure the <br> tag is respected */
}

.icons-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px; /* Add some space between the text and the icons */
}

.icons-container svg {
  margin: 0 5px; /* Add some space between the icons */
}

/* DOC SEARCH */
:root {
  /* primary-600 */
  --docsearch-primary-color: #7f56d9 !important;
}
button.DocSearch.DocSearch-Button {
  @apply !rounded-md md:min-w-[170px];
}
button.DocSearch.DocSearch-Button > span.DocSearch-Button-Keys {
  @apply !hidden;
}
.DocSearch-Button {
  @apply !text-gray-400 hover:!text-gray-600 active:!text-gray-600;
}
.DocSearch-Input {
  @apply !text-gray-600;
}
.DocSearch-Logo {
  @apply !hidden;
}

@keyframes loading {
  0% {
    background-size: 200% 200%;
    background-position: 100% 0;
  }
  100% {
    background-size: 200% 200%;
    background-position: -100% 0;
  }
}

.animate-loading {
  animation: loading 1.5s linear infinite;
  background-size: 200% 200%;
  background-image: linear-gradient(
    to right,
    #7f56d9 0%,
    #7f56d9 30%,
    #b692f6 50%,
    #7f56d9 70%,
    #7f56d9 100%
  );
}
