.hidden {
  display: none;
}

.css-11z9gm4 {
  padding: 0 !important;
}

@media (min-width: 1540px) {
  .css-11z9gm4 {
    padding: 12px !important;
  }
}
